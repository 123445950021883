import React, { useEffect } from 'react'
import { BrowserRouter, Switch, Route } from "react-router-dom"

// Components
import Navigation from '../layout/navigation'
import { Profile, ChangePass, AboutUs, WorkoutLog } from '../profile'
import PersonalWorkoutsPage from '../personalized/personalWorkoutsPage'
import Home from '../home'
import CategoryPage from '../category-page'
import WorkoutPage from '../workout-page'
import Login from '../auth/login'
import Signup from '../auth/signup'
import PassRecovery from '../auth/pass-recovery'
import SetPass from '../auth/set-pass'
import Alert from '../layout/alert'
import Events from '../events'
import EventPage from '../events/event-page'
import PlayGround from '../layout/pg'
import DesignSystem from '../layout/des-sys'
import Landing from '../landing/Landing'
import ExerciseList from '../exercise-list'
import DebCounter from '../deb'

// Private Route
import PrivateRoute from '../routing/PrivateRoute'

// Redux
import store, { persistor } from '../../store'
import { Provider } from 'react-redux'
import { loadUser } from '../../actions/auth'
import setAuthToken from '../../utils/setAuthToken'
// Persist Store
import { PersistGate } from 'redux-persist/integration/react'

import './app.css'

if (localStorage.token) {
    setAuthToken(localStorage.token)
}

const App = () => {

    useEffect(() => {
        store.dispatch(loadUser())
    }, [])

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <div className="App">
                    <BrowserRouter>
                        <Navigation />
                        <Alert />
                        <Switch>
                            <Route path="/" exact component={Landing} />
                            <Route path="/signup" exact component={Signup} />
                            <Route path="/login" exact component={Login} />
                            <Route path="/passrecovery" exact component={PassRecovery} />
                            <Route path="/setpassword/:id" exact component={SetPass} />
                            <Route path="/pg" exact component={PlayGround} />
                            <Route path="/des-sys" exact component={DesignSystem} />
                            <Route path="/debertz" exact component={DebCounter} />
                            <PrivateRoute path="/home" exact component={Home} />
                            <PrivateRoute path="/personalized" exact component={PersonalWorkoutsPage} />
                            <PrivateRoute path="/profile" exact component={Profile} />
                            <PrivateRoute path="/workout-log" exact component={WorkoutLog} />
                            <PrivateRoute path="/about-us" exact component={AboutUs} />
                            <PrivateRoute path="/change-pass" exact component={ChangePass} />
                            <PrivateRoute path="/categories/:id" exact component={CategoryPage} />
                            <PrivateRoute path="/workouts/:id" exact component={WorkoutPage} />
                            <PrivateRoute path="/events" exact component={Events} />
                            <PrivateRoute path="/events/:id" exact component={EventPage} />
                            <PrivateRoute path="/ex-list" exact component={ExerciseList} />
                            <Route render={() => <h2>Page not found, chobs!</h2>} />
                        </Switch>
                    </BrowserRouter>
                </div>
            </PersistGate>
        </Provider>
    )
}

export default App
