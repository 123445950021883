import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from '../../actions/auth'
import Spinner from '../layout/spinner'
import profileImage from './profile.png'
import { LockIcon, AboutUsIcon, WorkoutLogIcon } from '../../assets/icons'

import './profile.css'

const Profile = ({ logout, auth }) => {

    return (auth.loading) ? <Spinner /> : (
            <div>
                <img className="img-center-animated img-profile" src={profileImage} alt="Profile Icon" />
                <div className="profile-menu shadow">
                    <h3 className="text-center mt-4">Hello, {auth.user.name}</h3>
                    <ul>
                        <li className=""><WorkoutLogIcon /><Link to="/workout-log">Workout Log</Link></li>
                        <li className=""><AboutUsIcon /><Link to="/about-us">About Us</Link></li>
                        <li className=""><LockIcon /><Link to="/change-pass">Change Password</Link></li>
                    </ul>
                </div>
                <div className="col text-center">
                    <button className="btn-logout btn-orange shadow"
                        onClick={logout}>
                        Logout
                    </button>
                </div>
            </div>
        )
}

// Checkbox
// <div className="custom-control custom-checkbox text-center">
//     <input type="checkbox" className="custom-control-input check-large" id="customCheck1" />
//     <label className="custom-control-label check-text" htmlFor="customCheck1">Unsubscribe from all emails</label>
// </div>


const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps, { logout })(Profile)
